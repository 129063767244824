/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@font-face {
  font-family: 'museo-slab';    //This is what we are going to call
  src: url('./assets/fonts/Museo_Slab_500.otf');
}

ion-modal.adamAssignModal {
    margin: auto;
    --width: 80%;
    --background: white;
}


.editAlert textarea{
  height: 300px;  
}
.bulkDownloadModal {
  --height: 80%;
}

.bulkDownloadModal .modal-wrapper {
  max-height: 40vh;
  overflow: scroll;
  width: 100%;
  --height: 80%;
  display: clear;
}

.assign-new-attached-adam .modal-wrapper {
  display: flex;
  flex-direction: column;
  width:348px;
  height:233px;
}

.assign-new-attached-rfid .modal-wrapper {
  display: flex;
  flex-direction: column;
  width:348px;
  height:233px;
}

.select-icon {
    --color: black;
}

.waterBackground {
    --background: none;
    background-image: url("https://i.imgur.com/zHIXmgk.jpeg");
    background-size: cover;
    background-repeat: no-repeat;
}


button {
  width: 100%;
  height: 35px;
  border-radius: 5px;
  background-color: var(--ion-color-primary);
}

.csvBulkButton {
  width: 100%;
}
